<template>
  <div>
    <!-- Main Content Section -->
    <div class="container mb-2" v-loading="loading">
      <div class="row">
        <div class="col text-left">
          <div class="text-bold text-xl">{{lead.email}}</div>
          <div>{{lead.name}}</div>
        </div>
      </div>
      <h3 class="my-4">Duplicates</h3>
      <div class="row mt-3">
        <!-- Content Cards/Grid -->
        <div class="col-4" v-for="item in items" :key="item.id">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{{ item.email }}</h5>
              <p class="card-text">{{ item.first_name }}</p>
            </div>
            <div class="card-footer">
              <el-checkbox-group v-model="item.action" size="small" :max="1">
                <el-checkbox-button label="Duplicate" :key="`duplicate-lead-item` + item.email">Duplicate</el-checkbox-button>
                <el-checkbox-button label="Discard" :key="`not-duplicate-lead-item` + item.email">Discard</el-checkbox-button>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Section with Buttons -->
    <div class="container mt-3">
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
          <button type="button" class="btn btn-primary" :disabled="! selected.length" @click="SaveActions">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CheckboxButton, CheckboxGroup } from "element-ui";
import { LeadService } from "@/services";

export default {
  components: {
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  props: {
    leadId: {
      required: true,
    },
  },
  data: function() {
    return {
      loading: false,
      items: [
      ],
      lead: {},
    };
  }, 
  mounted() {
    this.loadData();
  },
  computed: {
    selected: function() {
      let data = [];
      this.items.forEach(item => {
        let selectedItem = {lead_id: item.lead_id, duplicate_id: item.duplicate_id, discarded: false, duplicate: false};
        if (item.action.indexOf('Discard') !== -1) {
          selectedItem.discarded = true;
          data.push(selectedItem);
        } else if (item.action.indexOf('Duplicate') !== -1) {
          selectedItem.duplicate = true;
          data.push(selectedItem);
        }
      });
      return data;
    },
  },
  methods: {
    async loadData() {
      let data = await LeadService.getLeadDuplicateDetails(this.leadId);
      let temp = [];
      data.data.duplicates.forEach(item => {
        item.action = [];
        temp.push(item);
      });

      this.items = temp;
      this.lead = data.data.lead;
    },
    async SaveActions() {
      this.loading = true;
      await LeadService.saveDuplicates(this.leadId, this.selected);
      this.loading = false;
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
