<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Lead Duplicates</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-6">
                <h3 class="mb-0" id="leads_main_heading">Lead Duplicates</h3>
              </div>
            </div>
          </template>
          <div>
            <div class="col-12 text-right">
              <div>
                <base-input
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Press ENTER to search"
                  @keyup.enter="search"
                ></base-input>
              </div>
            </div>

            <el-table
              :data="leads"
              row-key="id"
              v-loading="leadsLoading"
              header-row-class-name="thead-light"
              id="leads_table"
              @sort-change="sortChange"
              @row-click="rowClicked"
            >
              <el-table-column
                label="Type"
                prop="Email"
                min-width="70px"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  {{ row.email }}
                </template>
              </el-table-column>

              <el-table-column
                label="First Name"
                prop="first_name"
                min-width="60px"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  {{ row.first_name }}
                </template>
              </el-table-column>

              <el-table-column label="Last Name" prop="last_name" sortable="custom">
                <template v-slot="{ row }">
                  {{ row.last_name }}
                </template>
              </el-table-column>

              <el-table-column
                label="Enq. Date"
                prop="createdDate"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  {{ row.created_at | moment("DD MMM YYYY")  }}
                </template>
              </el-table-column>
              
              <el-table-column label="Duplicates" prop="duplicates_count" sortable="custom">
                <template v-slot="{ row }">
                  {{ row.duplicate_count }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog
      width="800px"
      v-loading="loading"
      :visible.sync="modalMerge"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-md"
    >
      <MergeLeads :leadId="selectedLead" @close="onClose" v-if="modalMerge"/>
    </el-dialog>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Dialog, OptionGroup } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import MergeLeads from '@/views/Widgets/LeadDuplicates/MergeLeads';
import { BasePagination } from "@/components";
import tableMixin from "@/helpers/remoteTableMixin";
import { mapState } from "vuex";
import { LeadService } from "@/services";

export default {
  mixins: [tableMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    MergeLeads,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [OptionGroup.name]: OptionGroup,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      leads: [],
      leadsLoading: true,
      modalMerge:false,
      selectedLead: '',
      loading: true,
    };
  },
  created() {
    this.loadPage(this.currentPage);
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdministrator"];
    },
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
  },
  methods: {
    rowClicked(row) {
      this.selectedLead = row.id;
      this.modalMerge = true;
    },

    async loadPage(page, search, sortBy, sortOrder) {
      this.leadsLoading = true;
      let leadData = await LeadService.paginateLeadDuplicates(page, search, sortBy, sortOrder, this.country);
      this.leads = leadData.data;
      this.pagination.total = parseInt(leadData.meta.total);
      this.pagination.perPage = parseInt(leadData.meta.per_page);
      this.leadsLoading = false;
    },

    onClose() {
      this.modalMerge = false;
      this.loadPage(this.pagination.currentPage, this.searchQuery, this.sortBy, this.sortOrder, this.country);
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
.el-table .cell {
  word-break: normal;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f6f9fc !important;
  cursor: pointer;
}
.el-select-group__title {
  text-transform: capitalize;
}
</style>
